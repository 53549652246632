* {
  font-family: 'Saira Semi Condensed', sans-serif;
}
body {
  background-color: #2461CE;
  color: #FFC400;
  background-image: url('/img/hellracers-footer-logo.png');
  background-size: 150px;
  background-repeat: no-repeat;
  background-position: right bottom;
}
h1, h2, h3, h4, h5 {
  font-family: 'Bungee', 'Saira Semi Condensed';
  text-transform: uppercase;
}

ul {
  list-style: none;
  padding-left: 0;
}